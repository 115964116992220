/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/index.scss"
// デザイン崩壊を避けるため、fontawesomeのCSSを静的に読み込む
// -> https://blog.uhy.ooo/entry/2020-05-10/gatsby-fontawesome-ssg/
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
