// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inherit-js": () => import("./../../../src/pages/inherit.js" /* webpackChunkName: "component---src-pages-inherit-js" */),
  "component---src-pages-invest-js": () => import("./../../../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-lend-js": () => import("./../../../src/pages/lend.js" /* webpackChunkName: "component---src-pages-lend-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-remodel-js": () => import("./../../../src/pages/remodel.js" /* webpackChunkName: "component---src-pages-remodel-js" */),
  "component---src-pages-rent-js": () => import("./../../../src/pages/rent.js" /* webpackChunkName: "component---src-pages-rent-js" */),
  "component---src-pages-sell-js": () => import("./../../../src/pages/sell.js" /* webpackChunkName: "component---src-pages-sell-js" */)
}

